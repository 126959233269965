import React, { useEffect, useState } from "react";
import ErrorOutline from "@mui/icons-material/ErrorOutline";
import { Box, Form, Progress, Typography } from "@periplus/ui-library";
import { useSnackbar } from "notistack";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import { Formik } from "formik";
import * as Yup from "yup";
import { passwordRegex } from "utils/password";
import { useTranslation } from "react-i18next";
import FormField from "../components/FormField";
import FormFieldLabel from "../components/FormFieldLabel";
import FormPasswordTextField from "../components/FormPasswordTextField";
import FormTitle from "../components/FormTitle";
import getDynamicBaseUrl from "utils/urlHelper";
import FormButton from "../components/FormButton";

export default () => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const queryParams = new URLSearchParams(useLocation().search);
  const resetToken = queryParams.get("reset-token");
  const [validationPending, setValidationPending] = useState(true);
  const [isInvalidToken, setIsInvalidToken] = useState(false);

  useEffect(() => {
    axios
      .post(
        `${getDynamicBaseUrl()}/auth/validate`,
        {
          token: resetToken,
        },
        { withCredentials: true }
      )
      .then(() => {
        setValidationPending(false);
      })
      .catch(() => {
        setValidationPending(false);
        setIsInvalidToken(true);
      });
  }, [resetToken]);

  const initialValues = {
    password: "",
    confirmPassword: "",
  };

  const validationSchema = Yup.object({
    password: Yup.string()
      .trim()
      .required()
      .matches(
        passwordRegex,
        t(
          "Password must contain at least one number, one lowercase and one uppercase letter"
        )
      ),
    confirmPassword: Yup.string()
      .trim()
      .required()
      .oneOf([Yup.ref("password"), null], t("Passwords did not match")),
  });

  const handleSubmit = async (values: typeof initialValues) => {
    let castedValues = validationSchema.cast(values) as typeof initialValues;
    try {
      await axios.post(
        `${getDynamicBaseUrl()}/auth/reset-password`,
        {
          password: castedValues.password,
          confirmPassword: castedValues.confirmPassword,
          resetToken,
        },
        { withCredentials: false }
      );
      enqueueSnackbar(t("Password reset successfully"), { variant: "success" });
      history.push("/login");
    } catch (err: any) {
      enqueueSnackbar(err.message, { variant: "error" });
    }
  };

  if (validationPending)
    return (
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Progress />
      </Box>
    );

  if (isInvalidToken)
    return (
      <Box
        sx={{
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <ErrorOutline color="error" style={{ fontSize: "6rem" }} />
        <Typography color="error">
          {t("Reset password link expired")}
        </Typography>
      </Box>
    );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ submitForm }) => (
        <Form
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            gap: 3,
          }}
        >
          <FormTitle>{t("New password")}</FormTitle>
          <FormField>
            <FormFieldLabel>{t("Password")}</FormFieldLabel>
            <FormPasswordTextField
              autoComplete="new-password"
              id="new-password"
              name="password"
              variant="outlined"
              fullWidth
              type="password"
            />
          </FormField>
          <FormField>
            <FormFieldLabel>{t("Confirm password")}</FormFieldLabel>
            <FormPasswordTextField
              name="confirmPassword"
              variant="outlined"
              fullWidth
              type="password"
            />
          </FormField>
          <FormButton
            variant="primary"
            onClick={submitForm}
            sx={{
              alignSelf: "center",
              minWidth: "100px",
            }}
          >
            {t("Submit")}
          </FormButton>
        </Form>
      )}
    </Formik>
  );
};
