import { LanguageType } from "i18n";

const clientId = ({ adit: "adit-spa", liber: "liber" } as const)[process.env.REACT_APP_NAME as "adit" | "liber"]

const development = {
  keycloak: {
    url: "https://authentication-staging.api.adit.periplus.ch/auth",
    realm: "adit",
    clientId,
    onLoad: "login-required",
    redirectUrl: "",
  },
  hasura: "http://localhost:1010/v1/graphql",
  blobStorage: {
    documentsContainerName: "documents",
    thumbsContainerName: "thumbs",
    storageAccountName: "aditdocuments",
  },
  prefixMRSNumber: "MRT",
  wedclare: "http://staging.wedeclare.ch",
};

const test = {
  keycloak: {
    url: "https://authentication-staging.api.adit.periplus.ch/auth",
    realm: "adit",
    clientId,
    onLoad: "login-required",
    redirectUrl: "",
  },
  hasura: "http://localhost:1010/v1/graphql",
  blobStorage: {
    documentsContainerName: "documents",
    thumbsContainerName: "thumbs",
    storageAccountName: "aditdocuments",
  },
  prefixMRSNumber: "MRT",
  wedclare: "http://staging.wedeclare.ch",
};

const demo = {
  keycloak: {
    url: "https://authentication-demo.api.adit.periplus.ch/auth",
    realm: "adit",
    clientId,
    onLoad: "login-required",
    redirectUrl: "",
  },
  hasura: "https://staging.api.adit.periplus.ch/v1/graphql",
  blobStorage: {
    documentsContainerName: "documents",
    thumbsContainerName: "thumbs",
    storageAccountName: "aditdocuments",
  },
  prefixMRSNumber: "MRT",
  wedclare: "http://demo.periplus.ch",
};

const staging = {
  keycloak: {
    url: "https://authentication-staging.api.adit.periplus.ch/auth",
    realm: "adit",
    clientId,
    onLoad: "login-required",
    redirectUrl: "",
  },
  hasura: "https://staging.api.adit.periplus.ch/v1/graphql",
  blobStorage: {
    documentsContainerName: "documents",
    thumbsContainerName: "thumbs",
    storageAccountName: "aditdocuments",
  },
  prefixMRSNumber: "MRT",
  wedclare: "http://staging.wedeclare.ch",
};

const production = {
  keycloak: {
    url: "https://authentication.api.adit.periplus.ch/auth",
    realm: "adit",
    clientId,
    onLoad: "login-required",
    redirectUrl: "https://adit.periplus.ch/login",
  },
  hasura: "https://production.api.adit.periplus.ch/v1/graphql",
  blobStorage: {
    documentsContainerName: "documents",
    thumbsContainerName: "thumbs",
    storageAccountName: "aditdocumentsproduction",
  },
  prefixMRSNumber: "MRS",
  wedclare: "https://portal.wedeclare.ch",
};

// Default to development if not set
function getConfig() {
  switch (process.env.REACT_APP_ENVIRONMENT) {
    case "development":
      return development;
    case "test":
      return test;
    case "staging":
      return staging;
    case "production":
      return production;
    case "demo":
      return demo;
    default:
      return development;
  }
}

// Default to development if not set
const config = getConfig();

const configs = {
  // All "common", general config or initial values belong here...
  START_MESSAGE: "Hello World",
  graphQl: {
    url: config.hasura || "http://localhost:5000",
  },
  isProdMode: process.env.REACT_APP_ENVIRONMENT === "production",
  maxFileSize: process.env.REACT_APP_MAX_FILE_SIZE || 1024 * 1024 * 100,
  defaultLanguage: "en" as LanguageType,
  usedLanguages: ["en", "de"] as LanguageType[],
  dateFormat: "DD.MM.YYYY",
  ...config,
};

export default configs;
