import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import axios from "axios";
import { Box, Loader } from "@periplus/ui-library";
import { MNR_TENANT_ID } from "domain/tenant/types";
import useUpdateCurrentUserUiSettings from "contexts/Auth/hooks/useUpdateCurrentUserUiSettings";
import useUpdateCurrentUserUiSettingsTemplates from "contexts/Auth/hooks/useUpdateCurrentUserUiSettingsTemplates";
import { getDecodedAccessToken } from "graphql/client";
import { useSnackbar } from "notistack";
import getDynamicBaseUrl from "utils/urlHelper";
import useGetCurrentUser, {
  CurrentUserEntity,
} from "./hooks/useGetCurrentUser";
import useGetCurrentUserTenantConfig from "./hooks/useGetCurrentUserTenantConfig";

export const SESSION_EXPIRED_STORAGE_KEY = "sessionExpired";
const PORTAL = ({ adit: "adit", liber: "wd" } as const)[process.env.REACT_APP_NAME as "adit" | "liber"];

export enum Role {
  Manager = "manager",
  Default = "default",
  TechnicalAdministrator = "technical_administrator",
  AlphaTester = "alpha_tester",
  CustomsBroker = "customs_broker",
  Importer = "importer",
  Supplier = "supplier",
  SupplierExtended = "supplier_extended",
  Administrator = "administrator",
  Editor = "editor",
  Viewer = "viewer",
  FreightForwarder = "freight_forwarder",
  TenantAdmin = "tenant_admin",
  Admin = "admin",
  WdFull = "wd_full",
}

export enum Permissions {
  DASH_EXC_CUST = "dash_exc_cust",
  DASH_VIA_CUST = "dash_via_cust",
  CHAT = "chat",
  DASHBOARD = "dashboard",
  CREATE_DEC = "create_dec",
  ADDRESS_MGT = "address_mgt",
  DASH_CONFIRM_ARRIVAL_BORDER = "dash_confirm_arrival_border",
  DASH_CONFIRM_ARRIVAL_AC = "dash_confirm_arrival_ac",
  ZOLL_BORDEREAU = "zoll_bordereau",
  REPORTING = "reporting",
  ADDRESS_DETAILS = "address_details",
  SUPPLIER_ADDRESS = "supplier_address",
  KPI_MGT = "kpi_mgt",
  ALERTS_MGT = "alerts_mgt",
  USER_MGT = "user_mgt",
  DOWNLOAD_DOCUMENTS = "download_documents",
  EDIT_DECLARATION = "edit_declaration",
  DOCUMENT_CLASIFICATION = "document_clasification",
  DOC_UPLOAD = "doc_upload",
}

export interface UserUiSettings {
  language?: string;
  sidebarOpen?: boolean;
  lastCheckedReleaseNotes?: string;
  pages: { [key: string]: any };
  [key: string]: any;
}

export interface UiSettingsTemplate {
  name: string;
  lastAppliedDate: string | null;
  settings: any;
}
interface UserUiSettingsTemplates {
  [key: string]: UiSettingsTemplate[];
}

type IUser = Omit<CurrentUserEntity, "ui_settings"> & {
  allowedRoles: Role[];
  hasAllowedRoles: (roles: Role[]) => boolean;
  userPermissions: Permissions[];
  hasAllowedPermissions: (permissions: Permissions[]) => boolean;
  uiSettings: UserUiSettings;
  uiSettingsTemplates: UserUiSettingsTemplates;
  isMnrUser: boolean;
};

interface IAuthContext {
  isAuthenticated: boolean;
  isAuthorized: boolean;
  user?: IUser;
  tenantConfig?: any;
  login: (username: string, password: string) => Promise<boolean>;
  twoFactorAuth: (code: string) => Promise<void>;
  microsoftLogin: (toke: string) => Promise<void>;
  logout: () => void;
  refetch: () => Promise<void>;
  updateUiSettings: (partialNewUiSettings: Partial<UserUiSettings>) => void;
  updateUiSettingsTemplates: (
    partialNewUiSettingsTemplates: UserUiSettingsTemplates
  ) => void;
}

const AuthContext = React.createContext<IAuthContext | undefined>(undefined);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within a AuthProvider");
  }
  return context;
};

export const AuthProvider = ({ children }: PropsWithChildren) => {
  const { enqueueSnackbar } = useSnackbar();
  const [twoFactorToken, setTwoFactorToken] = useState<string>();
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<IUser>();
  const [tenantConfig, setTenantConfig] = useState<any>();
  const isAuthenticated = Boolean(user);
  const isAuthorized = Boolean(
    user?.modules?.some((el) => el.name === PORTAL) && user.allowedRoles.length
  );

  const [getCurrentUser] = useGetCurrentUser();
  const [getCurrentUserTenantConfig] = useGetCurrentUserTenantConfig();

  const [updateCurrentUserUiSettings] = useUpdateCurrentUserUiSettings();
  const [updateCurrentUserUiSettingsTemplates] =
    useUpdateCurrentUserUiSettingsTemplates();

  const getUserData = useCallback(async () => {
    const decodedToken = await getDecodedAccessToken();
    if (!decodedToken) {
      setLoading(false);
      return;
    }

    setLoading(true);

    const { data } = await getCurrentUser();
    const dbUser = data?.user;
    if (!dbUser) {
      setLoading(false);
      return;
    }

    const { data: { getTenantConfig: currentUserTenantConfig } = {} } =
      await getCurrentUserTenantConfig({
        variables: {
          tenantId: dbUser.tenantId,
        },
      });
    setTenantConfig(currentUserTenantConfig);

    setUser({
      ...dbUser,
      allowedRoles:
        decodedToken!["https://hasura.io/jwt/claims"]["x-hasura-allowed-roles"],
      hasAllowedRoles(roles: Role[]) {
        return this.allowedRoles.some((allowedRole) =>
          roles.includes(allowedRole)
        );
      },
      userPermissions: data.user.permissionsDesk ?? [],
      hasAllowedPermissions(permissions: Permissions[]) {
        return !!this.userPermissions?.some((userPermission) => {
          return permissions.includes(userPermission);
        });
      },
      uiSettings: {
        pages: {},
        ...dbUser.uiSettings[PORTAL],
      },
      uiSettingsTemplates: dbUser.uiSettingsTemplates[PORTAL],
      isMnrUser: dbUser.tenantId === MNR_TENANT_ID,
    } as IUser);

    setLoading(false);
  }, [getCurrentUser, getCurrentUserTenantConfig]);

  useEffect(() => {
    getUserData();
    // eslint-disable-next-line
  }, []);

  const login: IAuthContext["login"] = useCallback(
    async (username, password) => {
      localStorage.setItem(SESSION_EXPIRED_STORAGE_KEY, JSON.stringify(false));
      try {
        const res = await axios.post(
          `${getDynamicBaseUrl()}/auth/login`,
          {
            username,
            password,
          },
          { withCredentials: true }
        );
        if (res.data.data?.is2FaEnabled) {
          setTwoFactorToken(res.data.data.token);
          return true;
        }
        await getUserData();
      } catch (err: any) {
        enqueueSnackbar(err?.response?.data?.message || err?.message, {
          variant: "error",
        });
      }

      return false;
    },
    [getUserData, enqueueSnackbar]
  );

  const twoFactorAuth: IAuthContext["twoFactorAuth"] = useCallback(
    async (code) => {
      try {
        await axios.post(
          `${getDynamicBaseUrl()}/auth/2fa/authenticate`,
          {
            authCode: code,
          },
          {
            withCredentials: true,
            headers: { Authorization: `Bearer ${twoFactorToken}` },
          }
        );
        await getUserData();
      } catch (err: any) {
        enqueueSnackbar(err?.response?.data?.message || err?.message, {
          variant: "error",
        });
      }
    },
    [getUserData, enqueueSnackbar, twoFactorToken]
  );

  const microsoftLogin: IAuthContext["microsoftLogin"] = useCallback(
    async (token) => {
      localStorage.setItem(SESSION_EXPIRED_STORAGE_KEY, JSON.stringify(false));
      try {
        await axios.post(
          `${getDynamicBaseUrl()}/auth/microsoft-login`,
          {},
          {
            headers: {
              authorization: `Bearer ${token}`,
            },
            withCredentials: true,
          }
        );
        await getUserData();
      } catch (err: any) {
        enqueueSnackbar(err?.response?.data?.message || err?.message, {
          variant: "error",
        });
      }
    },
    [getUserData, enqueueSnackbar]
  );

  const logout: IAuthContext["logout"] = useCallback(async () => {
    try {
      await axios.post(
        `${getDynamicBaseUrl()}/auth/logout`,
        {},
        {
          withCredentials: true,
        }
      );
      window.location.href = "/login";
      window.location.reload();
    } catch (err: any) {
      enqueueSnackbar(err?.response?.data?.message || err?.message, {
        variant: "error",
      });
    }
  }, [enqueueSnackbar]);

  const updateUiSettings: IAuthContext["updateUiSettings"] = useCallback(
    (partialNewUiSettings) => {
      const newUiSettings = {
        ...user!.uiSettings,
        ...partialNewUiSettings,
      };
      setUser((prev) => prev && { ...prev, uiSettings: newUiSettings });
      updateCurrentUserUiSettings({
        variables: {
          id: user!.userId,
          ui_settings: { [PORTAL]: newUiSettings },
        },
      });
    },
    [user, updateCurrentUserUiSettings]
  );

  const updateUiSettingsTemplates: IAuthContext["updateUiSettingsTemplates"] =
    useCallback(
      (partialNewUiSettingsTemplates: UserUiSettingsTemplates) => {
        const newUiSettingsTemplates = {
          ...user!.uiSettingsTemplates,
          ...partialNewUiSettingsTemplates,
        };
        setUser(
          (prev) =>
            prev && { ...prev, uiSettingsTemplates: newUiSettingsTemplates }
        );
        updateCurrentUserUiSettingsTemplates({
          variables: {
            id: user!.userId,
            ui_settings_templates: { [PORTAL]: newUiSettingsTemplates },
          },
        });
      },
      [user, updateCurrentUserUiSettingsTemplates]
    );

  if (!user && loading) {
    return (
      <Box
        sx={{
          height: "100vh",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Loader />
      </Box>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        isAuthorized,
        user,
        tenantConfig,
        login,
        twoFactorAuth,
        microsoftLogin,
        logout,
        refetch: getUserData,
        updateUiSettings,
        updateUiSettingsTemplates,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
