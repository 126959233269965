import { InMemoryCache } from "@apollo/client/cache";
import {
  ApolloClient,
  ApolloLink,
  split,
  HttpLink,
  ServerError,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import { RetryLink } from "@apollo/client/link/retry";
import { WebSocketLink } from "@apollo/client/link/ws";
import { getMainDefinition } from "@apollo/client/utilities";
import config from "config";
import axios, { AxiosError } from "axios";
import getDynamicBaseUrl from "utils/urlHelper";
import { snackbarDispatcher } from "../contexts/SnackbarContext";
import i18n from "i18n";
import jwt_decode from "jwt-decode";
import { Role, SESSION_EXPIRED_STORAGE_KEY } from "contexts/Auth/AuthContext";
import { removeTypenameFromVariables } from "@apollo/client/link/remove-typename";

interface OperationContext {
  disableErrorNotification?: boolean;
}

interface AccessToken {
  exp: number;
  "https://hasura.io/jwt/claims": {
    "x-hasura-allowed-roles": Role[];
  };
}

export function createApolloContext(
  context: OperationContext
): OperationContext {
  return context;
}

let _accessToken: string | null = null;

const handleUnauthenticatedError = () => {
  if (_accessToken) {
    localStorage.setItem(SESSION_EXPIRED_STORAGE_KEY, JSON.stringify(true));
    window.location.href = "/login";
    window.location.reload();
  }
};

export const getAccessToken = async () => {
  const refreshAccessToken = async () => {
    return axios
      .get(`${getDynamicBaseUrl()}/auth/refresh`, {
        withCredentials: true,
      })
      .then((response) => {
        _accessToken = response.data.data.token;
      })
      .catch((error: AxiosError) => {
        if (error.response?.status === 401) {
          handleUnauthenticatedError();
        }
      });
  };

  if (_accessToken) {
    const decodedAccessToken = jwt_decode<AccessToken>(_accessToken);
    const safetyExpirationMargin = 1 * 10 * 1000;
    if (decodedAccessToken.exp * 1000 - Date.now() < safetyExpirationMargin) {
      await refreshAccessToken();
    }
  } else {
    await refreshAccessToken();
  }

  return _accessToken;
};

export const getDecodedAccessToken = async () => {
  const accessToken = await getAccessToken();
  return accessToken ? jwt_decode<AccessToken>(accessToken) : null;
};

const removeTypenameLink = removeTypenameFromVariables();

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  const notifyAboutError = (errorType: "serverError" | "networkError") => {
    if (
      !(operation.getContext() as OperationContext).disableErrorNotification
    ) {
      const badUserInputErrors = graphQLErrors?.filter(
        (el) => el.extensions?.code === "BAD_USER_INPUT"
      );
      snackbarDispatcher.dispatch([
        badUserInputErrors?.length
          ? badUserInputErrors
            .map((el) => i18n.t(`serverErrors:${el.message}`))
            .join("\n")
          : (i18n.t(`error:${errorType}`) as string),
        { variant: "error" },
      ]);
    }
  };

  if (graphQLErrors) {
    if (
      graphQLErrors.some(
        (err) =>
          err.message.includes("JWTExpired") ||
          err.message.includes("Unauthorized") ||
          err.extensions?.code === "UNAUTHENTICATED"
      )
    ) {
      handleUnauthenticatedError();
      return;
    }
    notifyAboutError("serverError");
  }

  if (networkError) {
    if (
      networkError.name === "ServerError" &&
      (networkError as ServerError).statusCode === 401
    ) {
      handleUnauthenticatedError();
      return;
    }
    notifyAboutError("networkError");
  }
});

const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: Infinity,
    jitter: true,
  },
  attempts: {
    max: 5,
    retryIf: async (error) => {
      return !!error;
    },
  },
});

const withTokenLink = setContext(async (_, prevContext) => {
  const accessToken = await getAccessToken();

  return {
    ...prevContext,
    headers: {
      ...prevContext.headers,
      authorization: `Bearer ${accessToken}`,
    },
  };
});

const httpLink = new HttpLink({
  uri: config.graphQl.url,
});

const wsLink = new WebSocketLink({
  options: {
    connectionParams: async () => {
      const accessToken = await getAccessToken();
      return { headers: { authorization: `Bearer ${accessToken}` } };
    },
    lazy: true,
    reconnect: true,
    timeout: 30000,
  },
  uri: (() => {
    const uri = config.graphQl.url;
    if (uri.includes("https://")) {
      return uri.replace("https://", "wss://");
    }
    return uri.replace("http://", "ws://");
  })(),
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: ApolloLink.from([
    removeTypenameLink,
    split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return (
          definition.kind === "OperationDefinition" &&
          definition.operation === "subscription"
        );
      },
      wsLink,
      ApolloLink.from([errorLink, retryLink, withTokenLink, httpLink])
    ),
  ]),
  defaultOptions: {
    watchQuery: {
      errorPolicy: "all",
    },
    query: {
      errorPolicy: "all",
    },
  },
  connectToDevTools: process.env.NODE_ENV !== "production",
});

export default client;
