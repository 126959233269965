import React, { useEffect } from "react";
import {
  ProviderContext,
  SnackbarProvider as SnackbarProviderOriginal,
  SnackbarProviderProps,
  useSnackbar,
} from "notistack";
import Dispatcher from "utils/dispatcher";

export const snackbarDispatcher = new Dispatcher<
  Parameters<ProviderContext["enqueueSnackbar"]>
>();

const SnackbarDispatch = ({ children }: React.PropsWithChildren<any>) => {
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const unsubscribe = snackbarDispatcher?.subscribe((args) =>
      enqueueSnackbar(...args)
    );

    return () => {
      unsubscribe?.();
    };
  }, [enqueueSnackbar]);

  return children;
};

export const SnackbarProvider = ({
  children,
}: Pick<SnackbarProviderProps, "children">) => (
  <SnackbarProviderOriginal
    maxSnack={3}
    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
  >
    <SnackbarDispatch>{children}</SnackbarDispatch>
  </SnackbarProviderOriginal>
);
